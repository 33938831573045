<template>
    <div class="projects">
        <div class="projects__header">
            <div id="projects-header" class="projects__left-side"></div>

            <div class="projects__right-side">
                <CAction :actions="topActions"/>
            </div>
        </div>
        <ConfirmDialog ref="confirmDialog"/>
        <router-view/>
    </div>
</template>

<script>
import navLinkMixin from './navLinkMixin'
import CAction from '@/components/CAction'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'

export default {
    name: 'Projects',
    components: {CAction, ConfirmDialog},
    inject: ['toast'],
    mixins: [navLinkMixin],
    computed: {
        topActions() {
            let items = []
            if (!this.$route.params.task_id) return items

            if (this.$store.state.tasks.entity.canSkip) {
                items.push({label: 'Skip Task', event: () => this.handleSkip()})
            }

            if (this.$store.state.tasks.entity.canStop) {
                items.push({label: 'Stop Reccurence', event: () => this.handleStop()})
            }

            return items
        },
    },
    methods: {
        handleSkip() {
            this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you skip task?',
                    cancelText: 'Cancel',
                    confirmText: 'Confirm',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.$http.tasks.skip(this.$route.params.task_id)
                            .then(() => {
                                this.$store.dispatch('onTickerUpdate');
                                this.toast('info', 'The task has been skipped successfully')
                            })
                    }
                })
        },
        handleStop() {
            this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you stop task reccurence?',
                    cancelText: 'Cancel',
                    confirmText: 'Confirm',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.$http.tasks.stopRecurrence(this.$route.params.task_id)
                            .then(() => {
                                this.$store.dispatch('onTickerUpdate');
                                this.toast('info', 'The task recurrence has been stopped successfully');
                            })
                    }
                })
        },
    },
}
</script>

<style lang="scss">
.projects {
    padding-bottom: 20px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}
</style>
